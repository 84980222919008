<template>
  <div class="firmBox">
    <div class="firmNav">
      <img class="firmBei" src="../assets/img/Frame4668.png" alt="" />
    </div>

    <!-- 企业文化 -->
    <div class="referral">
      <div class="referralBox">
        <!-- <h3 class="referralTit">产品介绍</h3> -->
        <div class="referrMain">
          <div class="referr1" v-for="(item, index) in referrList" :key="index">
            <img :src="item.img" alt="" />
            <!-- <h3 class="referr1Tit">{{item.tit}}</h3>
                        <p class="referr1Main">{{item.main}}</p>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      referrList: [
        {
          img: require("../assets/img/Frame4248.png"),
          tit: "区域运营公司",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
        {
          img: require("../assets/img/Frame4249.png"),
          tit: "移动端工具",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
        {
          img: require("../assets/img/Frame4250.png"),
          tit: "电脑客户端",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.firmNav {
  height: 640px;
}
.referral {
  height: 537px;
  background-color: #fff;
}
.referralBox {
  padding-top: 80px;
  padding-left: 72px;
}
.referralTit {
  font-size: 28px;
}

.referrMain {
  display: flex;
  margin-top: 30px;
}
.referr1 {
  position: relative;
  margin-right: 30px;
  width: 412px;
  height: 308px;
  background-color: #f5f8ff;
  text-align: center;
  box-sizing: border-box;
}
.referr1Tit {
  width: 412px;
  font-size: 32px;
  position: absolute;
  top: 112px;
  color: #fff;
}
.referr1Main {
  width: 289px;
  margin-left: 73px;
  font-size: 16px;
  text-align: left;
  color: #fff;
  position: absolute;
  top: 180px;
}
</style>

